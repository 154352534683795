import React from 'react';
import ReactDOM from 'react-dom';
import {Products} from './Products';

ReactDOM.render(
    <React.StrictMode>
        <Products/>
    </React.StrictMode>,
    document.getElementById('products')
);
